export const functions = {
    props: {
        initialRecord: Object,
        initialOriginalRecord: Object
    },
    data() {
        return {
            record: {},
            changed: false,
            loading: true,
            loaded: false,
            original_record: {}
        };
    },
    mounted() {
        if(this.initialRecord) {
            this.record = Object.assign({}, this.record, this.initialRecord);
        }
        if(this.initialOriginalRecord) {
            this.original_record = Object.assign({}, this.original_record, this.initialOriginalRecord);
        }
        this.load();
    },
    beforeRouteLeave(to, from, next) {
        if(this.changed) {
            const answer = window.confirm('Er zijn niet opgeslagen wijzigingen. Wil je de pagina verlaten?');
            return answer ? next() : next(false);
        }
        return next();
    },
    watch: {
        record: {
            handler() {
                this.changed = true;
            },
            deep: true
        }
    },
    methods: {
        // Translation helper function.
        t(key) {
            return this.$t(this.crud.model + '.' + key);
        },
        /**
         * Helper that calls update() with params from the route and the current record.
         * @return {Promise}
         */
        updateFromRoute() {
            this.loading = true;
            return this.update(this.$route.params.id, this.record).then((response) => {
                // Update the original record with the returned data.
                this.original_record = Object.assign({}, this.original_record, JSON.parse( JSON.stringify( response.data ) ));
                this.record = Object.assign({}, this.record, JSON.parse( JSON.stringify( response.data ) ));
                this.$nextTick(() => {
                    this.changed = false;
                });
                this.loading = false;
            }).catch(() => { this.loading = false; });
        },
        /**
         * @param {Number} id
         * @param {Object} data
         * @param {Object} [options]
         * @param {Boolean} [options.notify=true]
         * @return {Promise}
         */
        update(id, data, options) {
            options = {...{
                notify: true
            }, ...options};
            return this.$http.put(this.crud.model + '/' + id, data).then((response) => {
                if(options.notify) {
                    this.$toastr.s(this.$t('notifications.updated', {object: this.$t('crud.' + this.crud.name + '._singular') + ' ' + id}));
                }
                return response;
            });
        },
        /**
         * @returns {Promise}
         */
        create() {
            this.loading = true;
            return this.$http.post(this.crud.model, this.record).then((response) => {
                this.$toastr.s(this.$t('notifications.created', {object: this.$t('crud.' + this.crud.name + '._singular')}));
                // Update the original record with the returned data.
                this.original_record = Object.assign({}, this.original_record, response.data);
                this.changed = false;
                this.loading = false;
                this.$router.push({name: this.crud.routes.index});
            }).catch(() => { this.loading = false; });
        },
        load(force) {
            if(!force && !this.$route.params.id) {
                // Skip if there's no ID in the route.
                return;
            }
            this.loading = true;
            return this.$http.get(this.crud.model + '/' + this.$route.params.id).then((response) => {
                this.record = Object.assign({}, this.record, JSON.parse( JSON.stringify( response.data ) ));
                this.$emit('loaded', this.record);
                this.original_record = Object.assign({}, this.original_record, JSON.parse( JSON.stringify( response.data ) ));
                this.$nextTick(() => {
                    this.changed = false;
                    this.loading = false;
                    this.loaded = true;
                });
            }).catch((error) => {
                this.loading = false;
                throw error;
            })
        },
        removeFromRoute()
        {
            return this.remove(this.$route.params.id).then(() => {
                // @TODO redirect to index.
            });
        },
        /**
         * @param {Number|String} id
         * @param {Object} [options]
         * @param {Boolean} [options.notify=true]
         * @param {Boolean} [options.force=false]
         * @return {Promise}
         */
        remove(id, options) {
            options = {...{
                notify: true,
                force: false
            }, ...options};
            let msg = this.$t('notifications.confirm_deletion', {object: this.$t('crud.' + this.crud.name + '._singular')});
            if(options.force || confirm(msg)) {
                return this.$http.delete('/' + this.crud.model + '/' + id).then(() => {
                    if(options.notify) {
                        this.$toastr.s(this.$t('notifications.deleted', {object: this.$t('crud.' + this.crud.name + '._singular') + ' ' + id}));
                    }
                    if(this.$refs.vuetable) {
                        this.$refs.vuetable.reload();
                    }
                });
            }
            return Promise.reject();
        },
        /**
         * @param {Number|String} id
         * @param {Object} [options]
         * @param {Boolean} [options.notify=true]
         * @param {Boolean} [options.force=false]
         * @return {Promise}
         */
        undoRemove(id, options) {
            options = {...{
                    notify: true,
                    force: false
                }, ...options};
            let msg = this.$t('notifications.confirm_undo_deletion', {object: this.$t('crud.' + this.crud.name + '._singular')});
            if(options.force || confirm(msg)) {
                return this.$http.post('/' + this.crud.model + '/' + id + '/restore').then(() => {
                    if(options.notify) {
                        this.$toastr.s(this.$t('notifications.undone_deletion', {object: this.$t('crud.' + this.crud.name + '._singular') + ' ' + id}));
                    }
                    if(this.$refs.vuetable) {
                        this.$refs.vuetable.reload();
                    }
                });
            }
            return Promise.reject();
        }
    }
};

export function config(name) {
    let config = _config[name] || defaults;
    // Add name as property so we dont have to double define it below.
    config.name = name;
    return config;
}

const _config = {
    orders: {
        model: 'orders',
        routes: {
            index: 'orders.index',
            //create: 'orders.create'
        }
    },
    pages: {
        model: 'pages',
        routes: {
            index: 'pages.index',
            create: 'pages.create'
        }
    },
    payment_methods: {
        model: 'payment-methods',
        routes: {
            index: 'payment_methods.index',
            create: 'payment_methods.create'
        }
    },
    pickup_locations: {
        model: 'pickup-locations',
        routes: {
            index: 'pickup_locations.index',
            create: 'pickup_locations.create'
        }
    },
    products: {
        model: 'products',
        routes: {
            index: 'products.index',
            create: 'products.create'
        }
    },
    transporters: {
        model: 'transporters',
        routes: {
            index: 'transporters.index',
            create: 'transporters.create'
        }
    },
    transporter_zones: {
        model: 'transporter-zones',
        routes: {
            index: 'transporter_zones.index',
            create: 'transporter_zones.create'
        }
    },
    users: {
        model: 'users',
        routes: {
            index: 'users.index',
            create: 'users.create'
        }
    },
    zones: {
        model: 'zones',
        routes: {
            index: 'zones.index',
            create: 'zones.create'
        }
    }
};

const defaults = {
    model:  'model-not-found',
    routes: {
        index: undefined,
        create: undefined
    }
};
