<template>
    <form @submit.prevent="submit()">
        <b-form-group :label="$t('crud.name')">
            <b-form-input v-model="record.name" required></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.transporter_zones.max_pallets_per_day')">
            <b-form-input v-model="record.max_pallets_per_day"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('crud.transporters._singular')">
            <b-form-select v-model="record.transporter_id" :options="transporters" text-field="name" value-field="id" required>
                <template slot="first">
                    <option :value="undefined" disabled>{{ $t('crud.choose_option') }}</option>
                </template>
            </b-form-select>
        </b-form-group>
        <button class="btn btn-primary" type="submit">{{ $t('crud.save') }}</button>
    </form>
</template>

<script>
    export default {
        props: ['record'],
        mounted() {
            this.$http.get('transporters').then((response) => {
                this.transporters = response.data;
            })
        },
        data() {
            return {
                transporters: []
            }
        },
        methods: {
            submit() {
                this.$emit('submit');
            }
        }
    }
</script>
